






































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class GaleriaHome extends Vue {
  private galeria: Array<any> = [];
  private horizontalPercentageInteres = 0;
  private total = 0;
  private created() {
    this.getGaleria();
    this.autoScrollGaleria();
  }

  private getGaleria() {
    this.$axios
      .get("galeria/home")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.galeria = res.data;
        // console.log(this.galeria);
        for (let i = 0; i < this.galeria.length; i++) {
          if (
            this.galeria[i].imagen_previa &&
            this.galeria[i].imagen_previa != ""
          ) {
            this.galeria[i].imagen_previa =
              base + this.galeria[i].imagen_previa;
            this.galeria[i].imagen_previa;
          } else {
            this.galeria[
              i
            ].imagen_previa = require("@/assets/logos/logo300.webp");
          }
        }
        this.total = res.data.total;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private autoScrollGaleria() {
    this.scroll("derecha");
    setTimeout(this.autoScrollGaleria, 5000);
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }

  // SCROLL CARROUSEL GALERIA
  private scroll(direccion: string) {
    // console.log(this.$refs.scrollInteres);
    if (this.$refs.scrollInteres as any) {
      const size = (this.$refs.scrollInteres as any).scrollSize;
      const position = (this.$refs.scrollInteres as any).scrollPosition;
      // console.log("size", size);
      //       console.log("position", position);

      if (direccion == "derecha") {
        // console.log("horizontalporcentage", this.horizontalPercentageInteres);
        if (this.horizontalPercentageInteres >= 0.9) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position + 230,
            150
          );
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
          } else {
            (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position - 230,
            150
          );
        }
      }
    }
  }

  private irGaleria(nombre_web: any) {
    if (
      this.$router.currentRoute.name == "DetalleGaleria" &&
      this.$router.currentRoute.params.nombre == nombre_web
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleGaleria",
          params: { nombre: nombre_web },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
